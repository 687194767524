var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post-styles-setup"},[(_vm.styleCopy)?[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.inputSetup,
        'args': {
          'model': _vm.styleCopy,
          'key': 'protect_content',
          'prefix': 'editor_options_',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.inputSetup,
        'args': {
          'model': _vm.styleCopy,
          'key': 'disable_notify',
          'prefix': 'editor_options_',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.inputSetup,
        'args': {
          'model': _vm.styleCopy,
          'key': 'disable_link_preview',
          'prefix': 'editor_options_',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.inputSetup,
        'args': {
          'model': _vm.styleCopy,
          'key': 'pin',
          'prefix': 'editor_options_',
          'hasAnchor': true,
        }
      }}}),(_vm.showOptions)?[_c('multi-message-editor-with-media-input',{staticClass:"w-full",attrs:{"setup":{
          'func': _vm.inputSetup,
          'args': {
            'model': _vm.styleCopy,
            'key': 'prefix_message',
            'hasMedia': _vm.styleCopy.prefix_message && !!_vm.styleCopy.prefix_message.length && !!_vm.styleCopy.prefix_message[0].attachments.length,
            'availableButtonsTypes': _vm.defaultMessageEditorButtons.filter(function (b) { return b.name !== 'TriggerCall'; }),
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'targetToUpload': function () { return _vm.boardUploadTarget(_vm.board); },
            'blacklistMediaButtonTypes': [ _vm.FileType.MessagePreview ]
          }
        }}}),_c('multi-message-editor-with-media-input',{staticClass:"w-full",attrs:{"setup":{
          'func': _vm.inputSetup,
          'args': {
            'model': _vm.styleCopy,
            'hasMedia': _vm.styleCopy.postfix_message && !!_vm.styleCopy.postfix_message.length && !!_vm.styleCopy.postfix_message[0].attachments.length,
            'key': 'postfix_message',
            'availableButtonsTypes': _vm.defaultMessageEditorButtons.filter(function (b) { return b.name !== 'TriggerCall'; }),
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'targetToUpload': function () { return _vm.boardUploadTarget(_vm.board); },
            'blacklistMediaButtonTypes': [ _vm.FileType.MessagePreview ]
          }
        }}}),_c('select-input',{attrs:{"setup":{
          'func': _vm.inputSetup,
          'args': {
            'model': _vm.styleCopy,
            'key': 'watermark_type',
            'options': _vm.watermarkTypes,
            'multiple': false,
            'clearable': false,
            'inline': false
          },
        }},on:{"input":_vm.onSelectChange}}),(_vm.styleCopy.watermark_type !== 'None' && _vm.styleCopy.watermark_type !== null )?_c('select-input',{attrs:{"setup":{
          'func': _vm.inputSetup,
          'args': {
            'model': _vm.styleCopy,
            'key': 'watermark_position',
            'options': _vm.watermarkPosition,
            'multiple': false,
            'clearable': false,
            'inline': false
          },
        }}}):_vm._e(),(_vm.styleCopy.watermark_type === 'Text')?_c('text-input',{attrs:{"setup":{
          'func': _vm.inputSetup,
          'args': {
            'model': _vm.styleCopy,
            'key': 'watermark_data',
            'validation': 'required',
          },
        }}}):_vm._e(),(_vm.styleCopy.watermark_type === 'File')?_c('file-uploader-targets-input',{staticClass:"watermark_uploader",attrs:{"setup":{
          'func': _vm.inputSetup,
          'args': {
            'model': _vm.styleCopy,
            'key': 'watermark_data',
            'multiple': false,
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'available-mime-types': ['image/png', 'image/jpeg' ],
            'targetToUpload': function () { return _vm.boardUploadTarget(_vm.board); }
          },
        }}}):_vm._e()]:_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }